.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  background: #fffbef;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}

#topVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.t-top {
  display: block;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  &__content {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px 0 368px 0;
  }
  &__what {
    display: block;
    margin: 0 0 40px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  }
  &__title {
    display: block;
    margin: 0 auto;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 92px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.t-header {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px 0;
  z-index: 9;
  & > .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__nav {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      a {
        display: inline-block;
        position: relative;
        padding: 6px 38px 6px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
          color: #ffbe00;
          text-decoration: none;
        }
      }
    }
  }
  &__right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    min-width: 472px;
    text-align: right;
  }
  &__phone {
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #ffbe00;
      text-decoration: none;
    }
  }
  &__link {
    display: block;
    margin: 4px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #ffbe00;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.2s ease-out;
    &:hover {
      color: #ffbe00;
      text-decoration: none;
    }
  }
}

.t-what {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -920px;
  padding: 678px 0 193px 0;
  background: url("../images/top_hard_bg.png") no-repeat center bottom;
  &:before {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: calc((100vw - 1200px) / 2 - 300px);
    height: calc(100% - 233px);
    background: #ffbe00;
    pointer-events: none;
    z-index: 1;
  }
  &:after {
    display: block;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: calc((100vw - 1200px) / 2 - 300px);
    height: calc(100% - 233px);
    background: #ffbe00;
    pointer-events: none;
    z-index: 1;
  }
  &__subtitle {
    display: block;
    margin: 0 0 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
  &__title {
    display: block;
    margin: 0 0 44px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  &__text {
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1d1d1d;
  }
  &__cards {
    display: block;
    position: relative;
    width: 635px;
    margin-left: 58px;
    padding: 80px;
    border-radius: 999rem;
    background: #ffffff;
    &-card {
      display: block;
      width: 474px;
      height: 474px;
      border-radius: 999rem;
      background-position: center center;
      background-size: cover;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 63px;
    height: 63px;
    outline: none;
    border-radius: 999rem;
    background: #d2232d;
    img,
    svg {
      display: block;
      max-width: 27px;
      max-height: 27px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #ffbe00;
      text-decoration: none;
    }
  }
  .swiper-button-prev {
    left: 50px;
  }
  .swiper-button-next {
    right: 50px;
  }
}

.t-categories {
  display: block;
  position: relative;
  margin-top: -150px;
  padding: 60px 0 0 0;
  background: url("../images/categories_bg.png") no-repeat left calc((100vw - 1200px) / 2) top;
  &__title {
    display: block;
    position: relative;
    margin: 175px 0 86px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  &-card {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 50px auto;
    background-position: center center;
    background-size: 100%;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 10px;
      left: 10px;
      width: 100%;
      height: 100%;
      border: 1px solid #2d962d;
      pointer-events: none;
      z-index: -1;
    }
    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
      width: 100%;
      min-height: 350px;
      padding: 25px 30px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      transition: all 0.2s ease-out;
    }
    &__title {
      display: block;
      margin: 0;
      font-family: Yeseva One;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      transition: all 0.2s ease-out;
    }
    &__link {
      display: flex;
      align-items: center;
      position: relative;
      margin: 17px 0 0 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      transition: all 0.2s ease-out;
      &-arrow {
        display: inline-block;
        width: 22px;
        height: 11px;
        margin-left: 7px;
        background: url("../images/icon_arrow_right_long.png") no-repeat right center;
        transition: all 0.2s ease-out;
        filter: brightness(0) invert(1);
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out;
    &:hover {
      .t-categories-card__link {
        color: #ffbe00;
      }
      .t-categories-card__link-arrow {
        width: 42px;
        filter: none;
      }
      background-size: 120%;
      text-decoration: none;
    }
  }
}

.t-goods {
  display: block;
  position: relative;
  padding: 50px 0 0 0;
  &__title {
    display: block;
    margin: 0 0 86px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 270px;
    margin: 0 0 60px 0;
    text-align: center;
    &__image {
      display: block;
      position: relative;
      width: 270px;
      height: 270px;
      border-radius: 999rem;
      background-position: center center;
      background-size: 100%;
      transition: all 0.6s ease-out;
      &:after {
        display: block;
        position: absolute;
        content: "";
        top: 15px;
        left: 15px;
        width: 100%;
        height: 100%;
        border-radius: 999rem;
        border: 1px solid #2d962d;
        pointer-events: none;
        z-index: -1;
        transition: all 0.2s ease-out;
      }
    }
    &__title {
      display: block;
      min-height: 70px;
      margin: 46px 0 11px 0;
      font-family: Yeseva One;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #1d1d1d;
      transition: all 0.2s ease-out;
    }
    &__price {
      display: block;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #1d1d1d;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      .t-goods-card__image {
        background-size: 120%;
      }
      .t-goods-card__title {
        color: #ffbe00;
      }
      text-decoration: none;
    }
  }
  &__more {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #1d1d1d;
    img,
    svg {
      display: inline-block;
      max-width: 99px;
      max-height: 99px;
      margin-left: 30px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: scale(1.1);
      }
      color: #d2232d;
      text-decoration: none;
    }
  }
}

.t-reviews {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 275px 0 350px 0;
  background: url("../images/reviews_bg_big_full.png") no-repeat top center;
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 226px;
    left: 0;
    width: 100%;
    height: 800px;
    background: #2d962d;
    pointer-events: none;
    z-index: -1;
  }
  &:after {
    display: block;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 800px;
    background: url("../images/reviews_after_svg.svg") no-repeat center top;
    background-size: 100% 100%;
    pointer-events: none;
    z-index: -1;
  }
  &__title {
    display: block;
    margin: 85px 0 0 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__image {
    display: block;
    width: 474px;
    height: 474px;
    margin-left: 60px;
    border-radius: 999rem;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0 0 80px #ffffff;
  }
  &__text {
    display: block;
    margin: 18px 0 56px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  &__price {
    display: block;
    margin: 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    color: #ffffff;
  }
  &__more {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #ffffff;
    img,
    svg {
      display: inline-block;
      max-width: 99px;
      max-height: 99px;
      margin-left: 30px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: scale(1.1);
      }
      color: #ffbe00;
      text-decoration: none;
    }
  }
  &-this {
    display: block;
    position: relative;
    padding-top: 140px;
    &__subtitle {
      display: block;
      margin: 90px 0 15px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      padding-top: 130px;
    }
    &__title {
      display: block;
      margin: 0;
      font-family: Yeseva One;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 46px;
      text-transform: uppercase;
      color: #ffffff;
    }
    &__cards {
      display: block;
      position: relative;
      width: 100%;
      max-width: 840px;
      background: url("../images/reviews_card_bg.png") no-repeat center;
      background-size: 100% 100%;
    }
    &-card {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 440px;
      padding: 60px 60px 20px 60px;
      text-align: center;
      &__image {
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 999rem;
        background-size: cover;
        background-position: center center;
      }
      &__title {
        display: block;
        margin: 16px 0 8px 0;
        font-family: Yeseva One;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 35px;
        color: #ffffff;
      }
      &__date {
        display: block;
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }
      &__text {
        display: block;
        height: 123px;
        overflow: hidden;
        margin: 18px 0 9px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      &__row {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        img,
        svg {
          display: block;
          max-width: 100px;
          max-height: 32px;
          margin-left: 10px;
        }
      }
    }
  }
  .swiper-pagination {
    position: relative;
    bottom: auto;
    width: auto;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #ffffff;
    pointer-events: none;
  }
  .swiper-pagination-current {
    font-size: 40px;
    line-height: 46px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 63px;
    height: 63px;
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 999rem;
    background: #ffbe00;
    img,
    svg {
      display: block;
      max-width: 27px;
      max-height: 27px;
      filter: brightness(0);
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: none;
      }
      background: #d2232d;
      text-decoration: none;
    }
  }
}

.t-features {
  display: block;
  position: relative;
  margin-top: -140px;
  padding: 0 0 70px 0;
  z-index: 2;
  &__subtitle {
    display: block;
    margin: 0 0 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
  &__title {
    display: block;
    margin: 0 0 75px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 570px;
    margin: 0 0 50px 0;
    &_1 {
      padding: 230px 0 0 70px;
      background: url("../images/features_1.png") no-repeat left 70px top;
    }
    &_2 {
      padding: 264px 0 0 100px;
      background: url("../images/features_2.png") no-repeat right top;
    }
    &_3 {
      padding: 130px 0 35px 0;
      background: url("../images/features_3.png") no-repeat right 70px top;
    }
    &_4 {
      padding: 220px 0 0 0;
      background: url("../images/features_4.png") no-repeat left 70px top;
    }
    &__title {
      display: block;
      margin: 0 0 17px 0;
      font-family: Yeseva One;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #1d1d1d;
    }
    &__text {
      display: block;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #1d1d1d;
    }
  }
}

.t-news {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 0 60px 0;
  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding-bottom: 75px;
  }
  &__row {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  &__subtitle {
    display: block;
    margin: 0 0 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
  &__title {
    display: block;
    margin: 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  .swiper-pagination {
    position: relative;
    bottom: auto;
    width: 150px;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #1d1d1d;
    pointer-events: none;
  }
  .swiper-pagination-current {
    font-size: 40px;
    line-height: 46px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 63px;
    height: 63px;
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 999rem;
    background: #d2232d;
    img,
    svg {
      display: block;
      max-width: 27px;
      max-height: 27px;
      transition: all 0.2s ease-out;
    }
    &:before,
    &:after {
      display: none;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0);
      }
      background: #ffbe00;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    width: 100%;
    overflow: visible !important;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 550px;
    background-position: center center;
    background-size: 100%;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 30px;
      left: 30px;
      width: 100%;
      height: 100%;
      border: 1px solid #2d962d;
      pointer-events: none;
      z-index: -1;
    }
    &__content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
      width: 100%;
      min-height: 350px;
      padding: 40px 50px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      transition: all 0.2s ease-out;
    }
    &__what {
      display: block;
      margin: 0 0 20px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      transition: all 0.2s ease-out;
    }
    &__title {
      display: block;
      margin: 0;
      font-family: Yeseva One;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out;
    &:hover {
      background-size: 120%;
      text-decoration: none;
    }
  }
}

.t-photos {
  display: block;
  position: relative;
  width: 100%;
  padding: 60px 0;
  &__subtitle {
    display: block;
    margin: 0 0 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
    z-index: 4;
  }
  &-card {
    display: block;
    position: relative;
    width: 100%;
    max-width: 170px;
    height: 170px;
    margin: 0 auto 30px auto;
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    z-index: 4;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.6s ease-out;
    &:hover {
      background-size: auto 120%;
      text-decoration: none;
    }
  }
  &__title {
    display: block;
    margin: 0 0 42px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
    z-index: 4;
  }
  &__more {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #1d1d1d;
    z-index: 4;
    img,
    svg {
      display: inline-block;
      max-width: 99px;
      max-height: 99px;
      margin-left: 30px;
      transition: all 0.2s ease-out;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        transform: scale(1.1);
      }
      color: #d2232d;
      text-decoration: none;
    }
  }
}

.t-bottom {
  display: block;
  position: relative;
  width: 100%;
  pointer-events: none;
  &__content {
    display: block;
    position: relative;
    width: 100%;
    max-width: 300px;
    min-height: 100vh;
    padding-bottom: 40px;
    &:after {
      display: block;
      position: absolute;
      content: "";
      left: -460px;
      top: -530px;
      width: 1075px;
      height: 1075px;
      border-radius: 999rem;
      background: #ffffff url("../images/bottom_after_bg_strange.png") no-repeat right 40px top 45px;
      z-index: 1;
      pointer-events: none;
    }
  }
  &__title {
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 0 36px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  &__phone {
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 0 36px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #d2232d;
    pointer-events: all;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      color: #2d962d;
      text-decoration: none;
    }
  }
  &__subtitle {
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 0 7px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #1d1d1d;
  }
  &__info {
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 0 30px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
    a {
      color: #1d1d1d;
      pointer-events: all;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover {
        color: #d2232d;
        text-decoration: none;
      }
    }
  }
  &__button {
    display: inline-block;
    position: relative;
    z-index: 2;
    padding: 15px 37px;
    border-radius: 999rem;
    background: #2d962d;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    pointer-events: all;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      background: #d2232d;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &__cards {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 45px;
  }
  &-card {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 212px;
    margin: 0 20px 20px 0;
    padding: 4px 20px;
    background: #ffffff;
    border-radius: 999rem;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1d1d1d;
    pointer-events: all;
    img,
    svg {
      display: block;
      max-width: 23px;
      max-height: 23px;
      margin-right: 16px;
      transition: all 0.2s ease-out;
    }
    &_active {
      img,
      svg {
        filter: brightness(0) invert(1) !important;
      }
      background: #2d962d !important;
      color: #ffffff !important;
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover {
      img,
      svg {
        filter: brightness(0) invert(1);
      }
      background: #2d962d;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

#map {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: all;
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(45, 150, 45, 0.15);
    z-index: 2;
    pointer-events: none;
  }
}

.t-footer {
  display: block;
  position: relative;
  padding: 20px 0;
  background: #2d962d;
  & > .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__info {
    display: block;
    margin: 5px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    a {
      color: #ffffff;
      cursor: pointer;
      text-decoration: underline;
      transition: all 0.2s ease-out;
      &:hover {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.t-subfooter {
  display: block;
  position: relative;
  padding: 11px 0;
  background: #1d1d1d;
  &__info {
    display: block;
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    opacity: 0.7;
    text-align: center;
  }
}

.t-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
  &__centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: #2d962d url("../images/modal_bg.svg") no-repeat center center;
    background-size: cover;
  }
  &__close {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 30px;
    z-index: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 99px;
      height: 99px;
      margin-right: 22px;
      border-radius: 999rem;
      background: #ffbe00;
      img,
      svg {
        display: block;
        max-width: 42px;
        max-height: 42px;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover,
    &:active,
    &:focus {
      color: #ffbe00;
      text-decoration: none;
    }
  }
  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 450px;
    margin: 30px 0;
    text-align: center;
  }
  input {
    display: block;
    width: 100%;
    max-width: 355px;
    margin: 0 auto 20px auto;
    padding: 13px 25px;
    background: #ffffff;
    border: 0;
    border-radius: 999rem;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
  &__title {
    display: block;
    position: relative;
    margin: 0 0 17px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__subtitle {
    display: block;
    margin: 0 0 30px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  &__info {
    display: block;
    margin: 33px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  &__button {
    display: inline-block;
    margin: 35px 0 0 0;
    padding: 15px 35px;
    border: 0;
    outline: none;
    background: #ffbe00;
    border-radius: 999rem;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1d1d1d;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover,
    &:active,
    &:focus {
      background: #d2232d;
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.t-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
  transform-style: preserve-3d;
  &__centered {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: #fffbef url("../images/popup_bg_alt.png") no-repeat center top;
    background-size: 100% 650px;
    /* &:before {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: calc((100vw - 1200px) / 2);
      height: 648px;
      background: #d2232d;
      z-index: 0;
      pointer-events: none;
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: calc((100vw - 1200px) / 2 - 120px);
      height: 707px;
      background: #d2232d;
      z-index: -1;
      pointer-events: none;
    } */
  }
  &__close {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 30px;
    z-index: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 99px;
      height: 99px;
      margin-right: 22px;
      border-radius: 999rem;
      background: #ffbe00;
      img,
      svg {
        display: block;
        max-width: 42px;
        max-height: 42px;
      }
    }
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out;
    &:hover,
    &:active,
    &:focus {
      color: #ffbe00;
      text-decoration: none;
    }
  }
  &__content {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin: 30px 0;
    padding: 70px 0;
  }
  &__title {
    display: block;
    position: relative;
    margin: 0 0 34px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 58px;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__image {
    display: block;
    position: relative;
    width: 474px;
    height: 474px;
    border-radius: 999rem;
    background-size: cover;
    background-position: center center;
    box-shadow: 0 0 0 80px #ffffff;
    transform-style: preserve-3d;
    &:after {
      display: block;
      position: absolute;
      content: "";
      top: -156px;
      left: -275px;
      width: 1021px;
      height: 841px;
      background: url("../images/popup_bg_after.png") no-repeat center center;
      transform: translateZ(-1px);
      pointer-events: none;
    }
  }
  &__text {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  &__subtitle {
    display: block;
    margin: 0 0 15px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
  &__what {
    display: block;
    position: relative;
    margin: 170px 0 50px 0;
    font-family: Yeseva One;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 58px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
  .table {
    text-align: center;
  }
  .table tr:first-child {
    td,
    th {
      border: 0;
    }
  }
  .table td,
  .table th {
    padding: 36px 6px;
    border-color: #2d962d;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #1d1d1d;
  }
  .table td:first-child,
  .table th:first-child {
    text-align: left;
  }
  .table td:last-child,
  .table th:last-child {
    text-align: right;
  }
  .table thead th {
    padding: 18px 6px;
    border-bottom: 1px solid #2d962d !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .t-bottom__content:after {
    display: none;
  }
  .t-top__title {
    font-size: 36px;
    line-height: 1.2;
  }
  .t-header__nav {
    min-width: auto;
  }
  .t-header__right {
    min-width: auto;
  }
  .t-what {
    margin: 0;
    padding: 40px 0;
    background: #ffbe00;
  }
  .t-top__content {
    padding: 20px 0 100px 0;
  }
  .t-header__logo {
    margin: 15px auto;
  }
  .t-header__right {
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .t-header__nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .t-header__nav li {
    min-width: 50%;
  }
  .t-header__nav li a {
    padding: 8px;
  }
  .t-what__cards-card {
    width: 300px;
    height: 300px;
  }
  .t-what__cards {
    width: 340px;
    padding: 20px;
    margin: 40px auto 0 auto;
  }
  .t-what .swiper-button-prev {
    left: 0;
  }
  .t-what .swiper-button-next {
    right: 0;
  }
  .t-categories {
    margin: 0;
    padding: 40px 0;
  }
  .t-categories__title {
    margin: 0 0 20px 0;
  }
  .t-goods {
    padding: 0;
  }
  .t-goods__title {
    margin-bottom: 20px;
  }
  .t-goods-card__title {
    margin: 20px 0 10px 0;
  }
  .t-goods-card {
    margin-bottom: 20px;
  }
  .t-goods__more {
    padding-top: 20px;
  }
  .t-reviews {
    margin: 0;
    padding: 40px 0;
    background: #2d962d;
    &:before,
    &:after {
      display: none;
    }
  }
  .t-reviews__image {
    width: 300px;
    height: 300px;
    margin: 0;
    box-shadow: 0 0 0 15px #ffffff;
  }
  .t-reviews__title {
    margin-top: 50px;
  }
  .t-reviews-this__subtitle {
    margin-top: 20px;
  }
  .t-reviews-this__row {
    padding: 20px 0;
  }
  .t-reviews-this {
    padding-top: 20px;
  }
  .t-reviews-this-card {
    padding: 60px 20px 20px 20px;
    background: transparent;
    border: 1px solid #ffffff;
  }
  .t-features {
    margin: 0;
    padding: 40px 0 0 0;
  }
  .t-features__title {
    margin-bottom: 20px;
  }
  .t-features-card_1 {
    background-position: left top;
  }
  .t-features-card_2 {
    background-position: right top;
    background-size: contain;
  }
  .t-features-card_3 {
    background-position: right top;
  }
  .t-features-card_4 {
    background-position: left top;
    background-size: contain;
  }
  .t-news__header {
    padding-bottom: 20px;
  }
  .t-news {
    padding: 40px 0;
  }
  .t-photos {
    padding: 40px 0;
  }
  #map {
    position: relative;
    height: 300px;
  }
  .t-bottom__content {
    min-height: auto;
  }
  .t-bottom__title {
    margin: 20px 0;
  }
  .t-modal__close-icon {
    width: 60px;
    height: 60px;
  }
  .t-modal__close {
    padding: 10px;
  }
  .t-popup__close-icon {
    width: 60px;
    height: 60px;
  }
  .t-popup__close {
    padding: 10px;
  }
  .t-popup__title {
    font-size: 42px;
  }
  .t-popup__image {
    width: 300px;
    height: 300px;
    margin: 40px 0;
    box-shadow: 0 0 0 15px #ffffff;
  }
  .t-popup__what {
    margin: 30px 0;
  }
  .t-popup__image:after {
    display: none;
  }
  .t-top__title {
    font-size: 32px;
  }
  .t-what__cards {
    width: 290px;
    padding: 10px;
  }
  .t-what__cards-card {
    width: 270px;
    height: 270px;
  }
  .t-categories__title {
    font-size: 32px;
  }
  .t-reviews-this__cards {
    background: transparent;
  }
  .t-reviews-this__cards {
    margin: 10px 0;
  }
  .t-bottom-card {
    max-width: 165px;
    margin: 0 5px 5px 0;
    padding: 4px 6px;
    font-size: 15px;
  }
  .t-bottom-card img,
  .t-bottom-card svg {
    margin-right: 6px;
  }
  .t-bottom__content {
    padding-bottom: 0;
  }
}
